const PMO_DATA = [
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "362290",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/362290",
    key: "IPP-996",
    fields: {
      summary: "Amaze SuperApp(OneID) : Phase2",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14934",
        value: "Customer",
        id: "14934",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-11-08",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A7d01abb7-3ca2-46f1-b817-335718e01b47",
        accountId: "712020:7d01abb7-3ca2-46f1-b817-335718e01b47",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/a5dda231b98368127df1ea64a451e238?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSP-5.png",
          "24x24":
            "https://secure.gravatar.com/avatar/a5dda231b98368127df1ea64a451e238?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSP-5.png",
          "16x16":
            "https://secure.gravatar.com/avatar/a5dda231b98368127df1ea64a451e238?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSP-5.png",
          "32x32":
            "https://secure.gravatar.com/avatar/a5dda231b98368127df1ea64a451e238?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSP-5.png",
        },
        displayName: "Sujinun P.",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: " Sujinun Punyapreechaphong",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "362268",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/362268",
    key: "IPP-995",
    fields: {
      summary: "Slab pricing phase2",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14936",
        value: "Product",
        id: "14936",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14957",
        value: "10%",
        id: "14957",
      },
      duedate: "2025-04-25",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A314ebd3c-3f7c-4fae-931c-b5eb1306a3d9",
        accountId: "712020:314ebd3c-3f7c-4fae-931c-b5eb1306a3d9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/5dcfb1c4eddb4f373e295ea2873e8d32?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FKS-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/5dcfb1c4eddb4f373e295ea2873e8d32?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FKS-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/5dcfb1c4eddb4f373e295ea2873e8d32?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FKS-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/5dcfb1c4eddb4f373e295ea2873e8d32?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FKS-1.png",
        },
        displayName: "kannaphat sutinwong",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "kannaphat.sutinwong@gmail.com",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "361193",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/361193",
    key: "IPP-993",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "ORWMS enhancement : Halal fresh item segregation alert",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14937",
        value: "Fulfillment",
        id: "14937",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-10-16",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14973",
        value: "Initial",
        id: "14973",
      },
      customfield_10671: "  Sarawut Thitinanthawornsuk",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ad5159293-e479-4d50-9b19-f7f354fe2f74",
        accountId: "712020:d5159293-e479-4d50-9b19-f7f354fe2f74",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
        },
        displayName: "Sarawut Thitinanthawornsuk",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "361183",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/361183",
    key: "IPP-992",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "[DC] Sort by Sequence",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14937",
        value: "Fulfillment",
        id: "14937",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14965",
        value: "90%",
        id: "14965",
      },
      duedate: "2024-11-20",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/16241",
        value: "Deployment",
        id: "16241",
      },
      customfield_10671: "  Sarawut Thitinanthawornsuk",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ad5159293-e479-4d50-9b19-f7f354fe2f74",
        accountId: "712020:d5159293-e479-4d50-9b19-f7f354fe2f74",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
        },
        displayName: "Sarawut Thitinanthawornsuk",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "358211",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/358211",
    key: "IPP-991",
    fields: {
      summary: "ORWMS v12 Porting from AIX to Linux",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14937",
        value: "Fulfillment",
        id: "14937",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14957",
        value: "10%",
        id: "14957",
      },
      duedate: "2024-12-15",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14977",
        value: "Analysis&Design",
        id: "14977",
      },
      customfield_10671: "Sarawut Thitinanthawornsuk",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ad5159293-e479-4d50-9b19-f7f354fe2f74",
        accountId: "712020:d5159293-e479-4d50-9b19-f7f354fe2f74",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
        },
        displayName: "Sarawut Thitinanthawornsuk",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "356279",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/356279",
    key: "IPP-990",
    fields: {
      summary: "System Monitoring",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14949",
        value: "Application Support",
        id: "14949",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14960",
        value: "40%",
        id: "14960",
      },
      duedate: "2024-11-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A490184bd-e736-475d-b416-fc409b2e8cbb",
        accountId: "712020:490184bd-e736-475d-b416-fc409b2e8cbb",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/80d7c0b3d3c5811bc7613d8bf25d3096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FK-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/80d7c0b3d3c5811bc7613d8bf25d3096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FK-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/80d7c0b3d3c5811bc7613d8bf25d3096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FK-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/80d7c0b3d3c5811bc7613d8bf25d3096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FK-3.png",
        },
        displayName: "Kraithep",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Kraithep Uttamo",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "356261",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/356261",
    key: "IPP-989",
    fields: {
      summary: "tenants portal - pocurement",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14964",
        value: "80%",
        id: "14964",
      },
      duedate: "2024-11-15",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Vanasnan Wongwian",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "356252",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/356252",
    key: "IPP-988",
    fields: {
      summary: "KTL - credit score",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14934",
        value: "Customer",
        id: "14934",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14964",
        value: "80%",
        id: "14964",
      },
      duedate: "2025-02-18",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Vanasnan Wongwian",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "348505",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/348505",
    key: "IPP-987",
    fields: {
      summary: "MTO (Multi-Tenant Organization)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-08-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "345092",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/345092",
    key: "IPP-986",
    fields: {
      summary: "Makro Staff Coin",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14962",
        value: "60%",
        id: "14962",
      },
      duedate: "2024-10-01",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=642262a06b29c052ab2ee412",
        accountId: "642262a06b29c052ab2ee412",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
        },
        displayName: "Gavin Amsap",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "gavin.amsap@lotuss.com",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "337804",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/337804",
    key: "IPP-985",
    fields: {
      summary: "sFTP Server : To migrate from VM to Container.",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14963",
        value: "70%",
        id: "14963",
      },
      duedate: "2024-08-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "337801",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/337801",
    key: "IPP-984",
    fields: {
      summary: "Lotus Tag : Enhancement to auto recheck tag and tag detection",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-04-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15019",
        value: "Rollout",
        id: "15019",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "337797",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/337797",
    key: "IPP-982",
    fields: {
      summary: "MILO (Migration & Relocation)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14957",
        value: "10%",
        id: "14957",
      },
      duedate: "2025-12-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "336924",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/336924",
    key: "IPP-981",
    fields: {
      summary: "Project : CMA - Order Review",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=642262a06b29c052ab2ee412",
        accountId: "642262a06b29c052ab2ee412",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
        },
        displayName: "Gavin Amsap",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: "2024-07-05",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "336923",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/336923",
    key: "IPP-980",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "Supply Chain Enhancement Y2024",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14937",
        value: "Fulfillment",
        id: "14937",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14963",
        value: "70%",
        id: "14963",
      },
      duedate: "2024-12-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=642262a06b29c052ab2ee412",
        accountId: "642262a06b29c052ab2ee412",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
        },
        displayName: "Gavin Amsap",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "gavin.amsap@lotuss.com",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "329405",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/329405",
    key: "IPP-979",
    fields: {
      summary: "Slab Pricing (Promotion)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14936",
        value: "Product",
        id: "14936",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-09-24",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=642262a06b29c052ab2ee412",
        accountId: "642262a06b29c052ab2ee412",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
        },
        displayName: "Gavin Amsap",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "gavin.amsap@lotuss.com",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "328805",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/328805",
    key: "IPP-978",
    fields: {
      summary: "TANZU Implement",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14957",
        value: "10%",
        id: "14957",
      },
      duedate: "2024-12-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "328804",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/328804",
    key: "IPP-977",
    fields: {
      summary: "CPAxtra Synergy (E-Mail)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14956",
        value: "0%",
        id: "14956",
      },
      duedate: "2024-12-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "328803",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/328803",
    key: "IPP-976",
    fields: {
      summary: "vRA and Enhancement Automation Management",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-11-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "328425",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/328425",
    key: "IPP-975",
    fields: {
      summary: "(Linux)New North DC project  (Unix - Server)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-08-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "328423",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/328423",
    key: "IPP-974",
    fields: {
      summary: "(Linux)Operating System End Of Support AIX",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-11-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "328325",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/328325",
    key: "IPP-972",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "Operating System End Of Support Redhat",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14962",
        value: "60%",
        id: "14962",
      },
      duedate: "2024-11-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: null,
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "327956",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/327956",
    key: "IPP-971",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "Share Drive Migration",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-12-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Worawut Charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "327941",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/327941",
    key: "IPP-970",
    fields: {
      summary: "MFC - Hyndai x O2O x Core IT",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15024",
        value: "O2O",
        id: "15024",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14959",
        value: "30%",
        id: "14959",
      },
      duedate: "2025-04-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      customfield_10671: "Nattavadee Eamsa-ard",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "327875",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/327875",
    key: "IPP-969",
    fields: {
      summary: "Price Change and Promotion Zone Group 2 (Regional Price)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14936",
        value: "Product",
        id: "14936",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-06-06",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15019",
        value: "Rollout",
        id: "15019",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=642262a06b29c052ab2ee412",
        accountId: "642262a06b29c052ab2ee412",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
        },
        displayName: "Gavin Amsap",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "gavin.amsap@lotuss.com",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "327787",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/327787",
    key: "IPP-968",
    fields: {
      summary: "Coin Rescheme",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14934",
        value: "Customer",
        id: "14934",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-06-28",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ae05d601e-0c9a-4861-8334-56d424153b19",
        accountId: "712020:e05d601e-0c9a-4861-8334-56d424153b19",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
        },
        displayName: "Natchinda Rungjaroenporn",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Natchinda Rungjaroenporn",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "327778",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/327778",
    key: "IPP-967",
    fields: {
      summary: "Store Specific Range & Store POG CT2023",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14936",
        value: "Product",
        id: "14936",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15022",
        value: "Off-track (Yellow)",
        id: "15022",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14963",
        value: "70%",
        id: "14963",
      },
      duedate: "2024-11-29",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      customfield_10671: "Pokkate",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11578",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "ACTIVE (Project Highlight)",
        id: "11578",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "327456",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/327456",
    key: "IPP-966",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "Mall Tenant Portal",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-09-02",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63c129110913ada386ab422d",
        accountId: "63c129110913ada386ab422d",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
        },
        displayName: "Nattanan.Rukchang",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Nattanan Rukchang",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "309633",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/309633",
    key: "IPP-964",
    fields: {
      summary:
        "DM-122 Delay on SOW sign of, pending AXONS target by end of Mar 24",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14958",
        value: "20%",
        id: "14958",
      },
      duedate: "2024-03-29",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ae05d601e-0c9a-4861-8334-56d424153b19",
        accountId: "712020:e05d601e-0c9a-4861-8334-56d424153b19",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
        },
        displayName: "Natchinda Rungjaroenporn",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Natchinda Rungjaroenporn",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10363",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Cancelled",
        id: "10363",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "307925",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/307925",
    key: "IPP-963",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "Customers : Referral Code",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14934",
        value: "Customer",
        id: "14934",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-07-03",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Vanasnan Wongwian",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "307090",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/307090",
    key: "IPP-962",
    fields: {
      summary: "Setting DLP for confidential information on 3 persons",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14945",
        value: "Solution Architects / Dev.Ops / IT Security",
        id: "14945",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15023",
        value: "At Risk (Red)",
        id: "15023",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14959",
        value: "30%",
        id: "14959",
      },
      duedate: "2024-02-19",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: null,
      customfield_10671: "theeradej.sahawattanapong@lotuss.com",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10363",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Cancelled",
        id: "10363",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "304071",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/304071",
    key: "IPP-961",
    fields: {
      summary: "Requrest_Upgrade Cortex XDR Agents 8.2.1",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14945",
        value: "Solution Architects / Dev.Ops / IT Security",
        id: "14945",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14959",
        value: "30%",
        id: "14959",
      },
      duedate: "2024-02-15",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: null,
      customfield_10671: "theeradej.sahawattanapong@lotuss.com",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10363",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Cancelled",
        id: "10363",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "303365",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/303365",
    key: "IPP-960",
    fields: {
      summary: "Speed up coin notification",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14934",
        value: "Customer",
        id: "14934",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-03-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15019",
        value: "Rollout",
        id: "15019",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Vanasnan Wongwian",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "303364",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/303364",
    key: "IPP-959",
    fields: {
      summary: "Coin rescheme",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14934",
        value: "Customer",
        id: "14934",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-02-01",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15019",
        value: "Rollout",
        id: "15019",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Vanasnan Wongwian",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "303358",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/303358",
    key: "IPP-958",
    fields: {
      summary: "Non-Thai registration",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14934",
        value: "Customer",
        id: "14934",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-07-16",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ae05d601e-0c9a-4861-8334-56d424153b19",
        accountId: "712020:e05d601e-0c9a-4861-8334-56d424153b19",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
        },
        displayName: "Natchinda Rungjaroenporn",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Natchinda Rungjaroenporn",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "295108",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/295108",
    key: "IPP-957",
    fields: {
      summary: "HR : WFM Project Rollout",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_11779: "1967-11-26",
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14963",
        value: "70%",
        id: "14963",
      },
      duedate: "2025-02-03",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Vanasnan W.",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "293977",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/293977",
    key: "IPP-956",
    fields: {
      summary: "Amaze SuperApp(OneID) : Phase1",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14934",
        value: "Customer",
        id: "14934",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-04-04",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15019",
        value: "Rollout",
        id: "15019",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=642262a06b29c052ab2ee412",
        accountId: "642262a06b29c052ab2ee412",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
        },
        displayName: "Gavin Amsap",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "gavin.amsap@lotuss.com",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "291717",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/291717",
    key: "IPP-953",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14933",
        value: "TH/MY",
        id: "14933",
      },
      summary: "DM-122 FM Maintenance",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14963",
        value: "70%",
        id: "14963",
      },
      duedate: "2025-01-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Natchinda Rungjaroenporn",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "291510",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/291510",
    key: "IPP-952",
    fields: {
      summary: "DM 149 DM149 Move DcKPI to Lotuss domain (WH Relocation)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14937",
        value: "Fulfillment",
        id: "14937",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14956",
        value: "0%",
        id: "14956",
      },
      duedate: "2024-06-08",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      customfield_10671: "Thanyanut Wuttithonvitit",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A64605208-0992-4bcb-a5c0-6cfe2371f7cf",
        accountId: "712020:64605208-0992-4bcb-a5c0-6cfe2371f7cf",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/cf1ebe77b7f2368367044a694bdcf6aa?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTW-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/cf1ebe77b7f2368367044a694bdcf6aa?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTW-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/cf1ebe77b7f2368367044a694bdcf6aa?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTW-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/cf1ebe77b7f2368367044a694bdcf6aa?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTW-1.png",
        },
        displayName: "Thanyanut Wuttithonvitit",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "290113",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/290113",
    key: "IPP-951",
    fields: {
      summary: "DM-144 O2O Roadmap 2023",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15024",
        value: "O2O",
        id: "15024",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-02-29",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14973",
        value: "Initial",
        id: "14973",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        accountId: "712020:0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
        },
        displayName: "anoot charoenkiattikul",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Anoot Charoenkiattikul",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "290086",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/290086",
    key: "IPP-950",
    fields: {
      summary: "DM-133 Lotus's Eat",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-05-07",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        accountId: "712020:0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
        },
        displayName: "anoot charoenkiattikul",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Anoot Charoenkiattikul",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "270804",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/270804",
    key: "IPP-942",
    fields: {
      summary: "DM97 -DTS Shop in Shop (IT Budget)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14939",
        value: "New Business",
        id: "14939",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14965",
        value: "90%",
        id: "14965",
      },
      duedate: "2024-01-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14973",
        value: "Initial",
        id: "14973",
      },
      customfield_10671: "Patchanee S.",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6219ce839cd4a70071e06fbc",
        accountId: "6219ce839cd4a70071e06fbc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
        },
        displayName: "Patchanee Sutadarat",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "270449",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/270449",
    key: "IPP-940",
    fields: {
      summary: "Bakery Automate Daily Production Plan - CY2023",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14938",
        value: "Store",
        id: "14938",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14959",
        value: "30%",
        id: "14959",
      },
      duedate: "2024-01-24",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      customfield_10671: "Chatchaya Sikarinth",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6224ea33a124500068895011",
        accountId: "6224ea33a124500068895011",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/f966f0df6d20436a98bcb3a2420f3576?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/f966f0df6d20436a98bcb3a2420f3576?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/f966f0df6d20436a98bcb3a2420f3576?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/f966f0df6d20436a98bcb3a2420f3576?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FC-3.png",
        },
        displayName: "chatchaya",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "270446",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/270446",
    key: "IPP-937",
    fields: {
      summary: "Stabilization - Data Reconciliation",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14949",
        value: "Application Support",
        id: "14949",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14957",
        value: "10%",
        id: "14957",
      },
      duedate: "2024-01-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: null,
      customfield_10671: "P'Panu",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10363",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Cancelled",
        id: "10363",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "270445",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/270445",
    key: "IPP-936",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "Stabilization - Systems Monitoring",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14949",
        value: "Application Support",
        id: "14949",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14957",
        value: "10%",
        id: "14957",
      },
      duedate: "2024-01-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: null,
      customfield_10671: "P'Panu",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10363",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Cancelled",
        id: "10363",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "258976",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/258976",
    key: "IPP-926",
    fields: {
      summary: "DM-142 Store Cash Office Fraud Prevention​ (Ph2)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15022",
        value: "Off-track (Yellow)",
        id: "15022",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14964",
        value: "80%",
        id: "14964",
      },
      duedate: "2024-08-19",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      customfield_10671: "Nattanan.Rukchang",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63c129110913ada386ab422d",
        accountId: "63c129110913ada386ab422d",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
        },
        displayName: "Nattanan.Rukchang",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "258929",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/258929",
    key: "IPP-923",
    fields: {
      summary: "HR : Digitalized Talent Acquisition",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-06-21",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      customfield_10671: "Nahathai Pipatpallop",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "258874",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/258874",
    key: "IPP-922",
    fields: {
      summary: "LDD/SoA (via R10)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14945",
        value: "Solution Architects / Dev.Ops / IT Security",
        id: "14945",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14965",
        value: "90%",
        id: "14965",
      },
      duedate: "2024-09-20",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A062f53eb-d3ac-49c3-bec5-b090ea3c9d00",
        accountId: "712020:062f53eb-d3ac-49c3-bec5-b090ea3c9d00",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/7538b12942f5e970500db2c46ac392f4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNE-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/7538b12942f5e970500db2c46ac392f4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNE-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/7538b12942f5e970500db2c46ac392f4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNE-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/7538b12942f5e970500db2c46ac392f4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNE-3.png",
        },
        displayName: "Nattavadee Eamsa-ard",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Nattavadee Eamsa-ard",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "253432",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/253432",
    key: "IPP-920",
    fields: {
      summary: "(EOL) Infra - Store - Network CY2023",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-01-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15020",
        value: "Procurement",
        id: "15020",
      },
      customfield_10671: "Thanatorn (Ake)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62e0d5ad3aaeedcae7560dfc",
        accountId: "62e0d5ad3aaeedcae7560dfc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "Teerawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "253431",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/253431",
    key: "IPP-919",
    fields: {
      summary: "(EOL) Infra - HO - Network CY2023",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-01-20",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15020",
        value: "Procurement",
        id: "15020",
      },
      customfield_10671: "Thanatorn (Ake)",
      assignee: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "252361",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/252361",
    key: "IPP-913",
    fields: {
      summary: "Data Customer Reporting-Roadmap CY2023",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14950",
        value: "Data Analytics",
        id: "14950",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14961",
        value: "50%",
        id: "14961",
      },
      duedate: "2024-02-29",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6178fad1b9c549006fe78883",
        accountId: "6178fad1b9c549006fe78883",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/d70fae949647e6e671bd9ffbd05be46b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FKP-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/d70fae949647e6e671bd9ffbd05be46b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FKP-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/d70fae949647e6e671bd9ffbd05be46b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FKP-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/d70fae949647e6e671bd9ffbd05be46b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FKP-4.png",
        },
        displayName: "Kumpol Panyawechmanit",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Kumpol Panyawechmanit",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "251134",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/251134",
    key: "IPP-912",
    fields: {
      summary: "Project Ex - Gas Station Sale API Interface (BCP)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14939",
        value: "New Business",
        id: "14939",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14963",
        value: "70%",
        id: "14963",
      },
      duedate: "2025-02-15",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14977",
        value: "Analysis&Design",
        id: "14977",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62455595f407980070564b05",
        accountId: "62455595f407980070564b05",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
        },
        displayName: "Suriyaphong Buntham",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Suriyaphong",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249908",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249908",
    key: "IPP-907",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "Data quality platform CY2023",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14936",
        value: "Product",
        id: "14936",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14963",
        value: "70%",
        id: "14963",
      },
      duedate: "2024-04-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        accountId: "712020:515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
        },
        displayName: "Jeranan Kaewdee",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Jeranan Kaewdee",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249906",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249906",
    key: "IPP-905",
    fields: {
      summary: "DM057 CN Allocation by Items (budget 425009 for R10)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14936",
        value: "Product",
        id: "14936",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14961",
        value: "50%",
        id: "14961",
      },
      duedate: "2024-12-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        accountId: "712020:515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
        },
        displayName: "Jeranan Kaewdee",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Jeranan Kaewdee",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249890",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249890",
    key: "IPP-889",
    fields: {
      summary: "DM085-Product and Price Enhancement (via R10)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14936",
        value: "Product",
        id: "14936",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-08-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      customfield_10671: "Sunisa",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6195ab41d2e64c0071ad8af8",
        accountId: "6195ab41d2e64c0071ad8af8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
        },
        displayName: "sunisa",
        active: true,
        timeZone: "Asia/Vientiane",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249863",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249863",
    key: "IPP-862",
    fields: {
      summary: "Separation Program - Store Systems (R10)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14938",
        value: "Store",
        id: "14938",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-09-13",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62875f9dc217e200698ecc03",
        accountId: "62875f9dc217e200698ecc03",
        avatarUrls: {
          "48x48":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/62875f9dc217e200698ecc03/9823a25e-5d1c-44b7-a4ec-a2d34c6fca94/48",
          "24x24":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/62875f9dc217e200698ecc03/9823a25e-5d1c-44b7-a4ec-a2d34c6fca94/24",
          "16x16":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/62875f9dc217e200698ecc03/9823a25e-5d1c-44b7-a4ec-a2d34c6fca94/16",
          "32x32":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/62875f9dc217e200698ecc03/9823a25e-5d1c-44b7-a4ec-a2d34c6fca94/32",
        },
        displayName: "Korawich Inthawong",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Nunnapat Lertmaneethaweesap",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249862",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249862",
    key: "IPP-861",
    fields: {
      summary: "Separation Program - Buy and Merchandise (Oracle SIM)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14938",
        value: "Store",
        id: "14938",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14964",
        value: "80%",
        id: "14964",
      },
      duedate: "2025-03-01",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62c7d7eba152cf973645aa14",
        accountId: "62c7d7eba152cf973645aa14",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
        },
        displayName: "Nunnapat L.",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Nunnapat Lertmaneethaweesap",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/11578",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "ACTIVE (Project Highlight)",
        id: "11578",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249855",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249855",
    key: "IPP-854",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary:
        "DM125_New Store DB Engine Replacement - Couchbase (via R10) for CMA Server (Couchbase Replacement) (via R10)",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14938",
        value: "Store",
        id: "14938",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15022",
        value: "Off-track (Yellow)",
        id: "15022",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14962",
        value: "60%",
        id: "14962",
      },
      duedate: "2024-03-28",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62c7d7eba152cf973645aa14",
        accountId: "62c7d7eba152cf973645aa14",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
        },
        displayName: "Nunnapat L.",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Nunnapat Lertmaneethaweesap",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249854",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249854",
    key: "IPP-853",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "Customer System Optimization - Mulesoft redesign CY2023",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14948",
        value: "Software Development",
        id: "14948",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-11-05",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15019",
        value: "Rollout",
        id: "15019",
      },
      customfield_10671: "Art Karusenee",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=603d91620a8cc500704e77bf",
        accountId: "603d91620a8cc500704e77bf",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/32b4c56fb7e77d562dac5b4a873b6041?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAK-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/32b4c56fb7e77d562dac5b4a873b6041?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAK-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/32b4c56fb7e77d562dac5b4a873b6041?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAK-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/32b4c56fb7e77d562dac5b4a873b6041?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAK-2.png",
        },
        displayName: "Art Karusenee",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249851",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249851",
    key: "IPP-850",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "IT Ticketing",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14940",
        value: "PMO",
        id: "14940",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-03-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15019",
        value: "Rollout",
        id: "15019",
      },
      customfield_10671: "Thames Supupkul",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A2d7bc80d-4fd1-4473-bf2e-b6a2c7ee0363",
        accountId: "712020:2d7bc80d-4fd1-4473-bf2e-b6a2c7ee0363",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/380c2ed4d9a8d9c660bdd71b8879af20?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNP-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/380c2ed4d9a8d9c660bdd71b8879af20?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNP-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/380c2ed4d9a8d9c660bdd71b8879af20?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNP-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/380c2ed4d9a8d9c660bdd71b8879af20?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNP-1.png",
        },
        displayName: "Nutthawut Phongsrida",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249849",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249849",
    key: "IPP-848",
    fields: {
      summary: "Project B2C Vending Machine Rollout Phase I  ",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14939",
        value: "New Business",
        id: "14939",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-02-21",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15019",
        value: "Rollout",
        id: "15019",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62455595f407980070564b05",
        accountId: "62455595f407980070564b05",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
        },
        displayName: "Suriyaphong Buntham",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Suriyaphong Buntham",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249847",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249847",
    key: "IPP-846",
    fields: {
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      summary: "CPFM Transition System to SIM & R10 - Pilot Store",
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14939",
        value: "New Business",
        id: "14939",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15022",
        value: "Off-track (Yellow)",
        id: "15022",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14963",
        value: "70%",
        id: "14963",
      },
      duedate: "2025-06-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15018",
        value: "UAT",
        id: "15018",
      },
      customfield_10671: "Patchanee S.",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6219ce839cd4a70071e06fbc",
        accountId: "6219ce839cd4a70071e06fbc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
        },
        displayName: "Patchanee Sutadarat",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249837",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249837",
    key: "IPP-836",
    fields: {
      summary: "(EOL) Infra - DC - Network CY2023",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-01-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14975",
        value: "Planning",
        id: "14975",
      },
      customfield_10671: "Thanatorn (Ake)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63d7705ddb4f715c971ee11b",
        accountId: "63d7705ddb4f715c971ee11b",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/b6d5c2b0b12d18f5788ee2a4e61537d4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/b6d5c2b0b12d18f5788ee2a4e61537d4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/b6d5c2b0b12d18f5788ee2a4e61537d4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/b6d5c2b0b12d18f5788ee2a4e61537d4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "thanathorn.praisri",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249815",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249815",
    key: "IPP-814",
    fields: {
      summary: "VmWare Revolution (TH) 2024",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14941",
        value: "Infrastructure",
        id: "14941",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14962",
        value: "60%",
        id: "14962",
      },
      duedate: "2024-12-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14976",
        value: "Requirement",
        id: "14976",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "worawut charoenchai",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249804",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249804",
    key: "IPP-803",
    fields: {
      summary: "New North DC (Business Budget)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14937",
        value: "Fulfillment",
        id: "14937",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-07-08",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6224ea65932f0f007169024a",
        accountId: "6224ea65932f0f007169024a",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
        },
        displayName: "wasuthep sarawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "wasuthep sarawan",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249790",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249790",
    key: "IPP-789",
    fields: {
      summary: "Finance: Makro Snerygy - Makro EPM",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: null,
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14959",
        value: "30%",
        id: "14959",
      },
      duedate: "2024-03-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14974",
        value: "On Hold",
        id: "14974",
      },
      customfield_10671: "Atcharapan (Garet)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62f36357da8620d53394e30b",
        accountId: "62f36357da8620d53394e30b",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
        },
        displayName: "Atcharapan Thongphet",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249788",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249788",
    key: "IPP-787",
    fields: {
      summary: "Finance: Enhance PO spend report  (ERP & Power BI) ",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: null,
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14957",
        value: "10%",
        id: "14957",
      },
      duedate: "2024-03-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14974",
        value: "On Hold",
        id: "14974",
      },
      customfield_10671: "Atcharapan (Garet)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62f36357da8620d53394e30b",
        accountId: "62f36357da8620d53394e30b",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
        },
        displayName: "Atcharapan Thongphet",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249787",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249787",
    key: "IPP-786",
    fields: {
      summary: "Finance: DM-115 Bidding workflow",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: null,
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14957",
        value: "10%",
        id: "14957",
      },
      duedate: "2024-03-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14974",
        value: "On Hold",
        id: "14974",
      },
      customfield_10671: "Thanyanut",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62f36357da8620d53394e30b",
        accountId: "62f36357da8620d53394e30b",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
        },
        displayName: "Atcharapan Thongphet",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249780",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249780",
    key: "IPP-779",
    fields: {
      summary: "DM-132 Lotus's Booking​",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: null,
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14980",
        value: "*** Please Identify",
        id: "14980",
      },
      duedate: "2024-03-31",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      customfield_10671: "Aektanat (Jack)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=624bdea08678e900705dddd9",
        accountId: "624bdea08678e900705dddd9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
        },
        displayName: "Aektanat Udomlumleart",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10352",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "On Hold",
        id: "10352",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249776",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249776",
    key: "IPP-775",
    fields: {
      summary: "DM-141 WH Tax agent​",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: null,
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14956",
        value: "0%",
        id: "14956",
      },
      duedate: "2024-12-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15020",
        value: "Procurement",
        id: "15020",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ae05d601e-0c9a-4861-8334-56d424153b19",
        accountId: "712020:e05d601e-0c9a-4861-8334-56d424153b19",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
        },
        displayName: "Natchinda Rungjaroenporn",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Aektanat (Jack)",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10364",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open from DM (temp.)",
        id: "10364",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249775",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249775",
    key: "IPP-774",
    fields: {
      summary: "DM-106 Deposit refund at HO (AUDIT)",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-05-16",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      customfield_10671: "Aektanat (Jack)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=624bdea08678e900705dddd9",
        accountId: "624bdea08678e900705dddd9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
        },
        displayName: "Aektanat Udomlumleart",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249771",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249771",
    key: "IPP-770",
    fields: {
      summary: "HR : HR Database – Insight Phase 2",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14961",
        value: "50%",
        id: "14961",
      },
      duedate: "2024-12-01",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14979",
        value: "Requirement Gathering",
        id: "14979",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Tavikarn Paisoonsin",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10350",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Active",
        id: "10350",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249768",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249768",
    key: "IPP-767",
    fields: {
      summary: " HR: Workforce management (WFM) - Phase2",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: null,
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14958",
        value: "20%",
        id: "14958",
      },
      duedate: "2024-04-30",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14973",
        value: "Initial",
        id: "14973",
      },
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61f1fdbde218f0006af7eef9",
        accountId: "61f1fdbde218f0006af7eef9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "tavikarn.paisoonsin",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      customfield_10671: "Tavikarn (Toey)",
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10363",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Cancelled",
        id: "10363",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249753",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249753",
    key: "IPP-752",
    fields: {
      summary: "Mall Data Mart CY2023",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14935",
        value: "Finance",
        id: "14935",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-11-04",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15017",
        value: "Implement",
        id: "15017",
      },
      customfield_10671: "Aektanat (Jack)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=624bdea08678e900705dddd9",
        accountId: "624bdea08678e900705dddd9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/3f5ddb1f8a6fc8a8e8d2ed54e42674e4?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAU-4.png",
        },
        displayName: "Aektanat Udomlumleart",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "249738",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/249738",
    key: "IPP-737",
    fields: {
      summary: "Data Report Enhancement",
      customfield_10665: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14931",
        value: "Thailand - TH",
        id: "14931",
      },
      customfield_10666: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14950",
        value: "Data Analytics",
        id: "14950",
      },
      customfield_11779: null,
      customfield_10678: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15021",
        value: "On-track (Green)",
        id: "15021",
      },
      customfield_10668: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/14966",
        value: "100%",
        id: "14966",
      },
      duedate: "2024-03-15",
      customfield_10670: {
        self: "https://lotusretails.atlassian.net/rest/api/3/customFieldOption/15016",
        value: "Development",
        id: "15016",
      },
      customfield_10671: "Panaek Warawit",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6180c6ad3e3753006f6f655a",
        accountId: "6180c6ad3e3753006f6f655a",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/eff2f8bdd10419cd99cdf16f5feba6d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPW-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/eff2f8bdd10419cd99cdf16f5feba6d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPW-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/eff2f8bdd10419cd99cdf16f5feba6d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPW-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/eff2f8bdd10419cd99cdf16f5feba6d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPW-1.png",
        },
        displayName: "PanaEk Warawit",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10351",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Completed",
        id: "10351",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/3",
          id: 3,
          key: "done",
          colorName: "green",
          name: "Done",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "365391",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/365391",
    key: "DEM-276",
    fields: {
      summary: "iLearn - Makro",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61f1fdbde218f0006af7eef9",
        accountId: "61f1fdbde218f0006af7eef9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "tavikarn.paisoonsin",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10321",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Gate 1 -",
        id: "10321",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "364846",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/364846",
    key: "DEM-275",
    fields: {
      summary: "Project CPFM - Convert to SL Phase-II (166 store) ",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10325",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Gate 2 -",
        id: "10325",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "364617",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/364617",
    key: "DEM-274",
    fields: {
      summary: "QA Automation Phase1 : Server preparation",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=616d48cb2097220071948303",
        accountId: "616d48cb2097220071948303",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/8f636bb84dca2025bd4b1501b0815fbf?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FOS-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/8f636bb84dca2025bd4b1501b0815fbf?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FOS-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/8f636bb84dca2025bd4b1501b0815fbf?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FOS-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/8f636bb84dca2025bd4b1501b0815fbf?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FOS-6.png",
        },
        displayName: "Onica Srivicha",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "362418",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/362418",
    key: "DEM-273",
    fields: {
      summary: "SRD Heatmap publish to Store",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "362353",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/362353",
    key: "DEM-272",
    fields: {
      summary: "Employee Integration improvement - B2B/Mendix",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "361048",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/361048",
    key: "DEM-271",
    fields: {
      summary: "To create TMW into Lotus MY mobile app",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "360626",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/360626",
    key: "DEM-270",
    fields: {
      summary: "Store Request Phase2",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=622999df932f0f00716b1c2e",
        accountId: "622999df932f0f00716b1c2e",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
        },
        displayName: "Piengtawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "360410",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/360410",
    key: "DEM-269",
    fields: {
      summary:
        "[O2O] New Fulfillment system, replacing High jump for next day delivery",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "360406",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/360406",
    key: "DEM-268",
    fields: {
      summary: "Lotus's MY Play Game and Earn Points",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10325",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Gate 2 -",
        id: "10325",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "360392",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/360392",
    key: "DEM-267",
    fields: {
      summary: "[O2O] Coupon API for Phoenix project (web/LineOA)",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "358411",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/358411",
    key: "DEM-266",
    fields: {
      summary: "HLE Hub Synergy",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "355790",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/355790",
    key: "DEM-265",
    fields: {
      summary: "HLE Hub Bangna relocation",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "355422",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/355422",
    key: "DEM-264",
    fields: {
      summary: "Project CPFM - Convert to Storeline (GoFresh) (197 Store)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62455595f407980070564b05",
        accountId: "62455595f407980070564b05",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/916357fc0199f708c7b6a79812a26518?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSB-6.png",
        },
        displayName: "Suriyaphong Buntham",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "353167",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/353167",
    key: "DEM-263",
    fields: {
      summary:
        "Store Retail Services 2024 Project [Relocating Bill Payment servers from a th-tesco environment to Lotus’s.]",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63f2d8260154af3bb2ae3739",
        accountId: "63f2d8260154af3bb2ae3739",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/21400307da6d386f03cc5b443b1c5b12?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-5.png",
          "24x24":
            "https://secure.gravatar.com/avatar/21400307da6d386f03cc5b443b1c5b12?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-5.png",
          "16x16":
            "https://secure.gravatar.com/avatar/21400307da6d386f03cc5b443b1c5b12?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-5.png",
          "32x32":
            "https://secure.gravatar.com/avatar/21400307da6d386f03cc5b443b1c5b12?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-5.png",
        },
        displayName: "Pannee Sukim",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "352452",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/352452",
    key: "DEM-261",
    fields: {
      summary: "[O2O] Coupon API for Phoenix project",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62c79ab6b6357aecd7c7d2df",
        accountId: "62c79ab6b6357aecd7c7d2df",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ff3587f6ca43c4c9b41c322e8779ef98?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FCK-5.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ff3587f6ca43c4c9b41c322e8779ef98?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FCK-5.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ff3587f6ca43c4c9b41c322e8779ef98?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FCK-5.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ff3587f6ca43c4c9b41c322e8779ef98?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FCK-5.png",
        },
        displayName: "Chanon Kiatphaiboon",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "352326",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/352326",
    key: "DEM-260",
    fields: {
      summary: "Hardware Replacement for 6 DCs",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "352165",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/352165",
    key: "DEM-259",
    fields: {
      summary: "[DC] Sort by sequence",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ad5159293-e479-4d50-9b19-f7f354fe2f74",
        accountId: "712020:d5159293-e479-4d50-9b19-f7f354fe2f74",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
        },
        displayName: "Sarawut Thitinanthawornsuk",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "350751",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/350751",
    key: "DEM-258",
    fields: {
      summary: "[O2O] New payment gateway to replace cybersource design",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "350736",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/350736",
    key: "DEM-257",
    fields: {
      summary: "[O2O] Hato - e-Category for universal link",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "350730",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/350730",
    key: "DEM-256",
    fields: {
      summary: "[O2O] New Fulfillment system to replace High jump and Loginext",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "350348",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/350348",
    key: "DEM-255",
    fields: {
      summary: "[SOA/LDD] : Phoenix-MFC Integration Project",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "350338",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/350338",
    key: "DEM-254",
    fields: {
      summary: "[SOA] : INT_BUY_60665 SOA-Phoenix Integration Project",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "349049",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/349049",
    key: "DEM-252",
    fields: {
      summary: "Smart X : HLE Hub Application (Internet)",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "348992",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/348992",
    key: "DEM-251",
    fields: {
      summary: "Trukr Enhancement",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "348626",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/348626",
    key: "DEM-250",
    fields: {
      summary: "PMO Project Status update automation report and dashboard  ",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        accountId: "712020:515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
        },
        displayName: "Jeranan Kaewdee",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "348544",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/348544",
    key: "DEM-249",
    fields: {
      summary: "ORWMS v12 Porting from AIX to Linux​",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ad5159293-e479-4d50-9b19-f7f354fe2f74",
        accountId: "712020:d5159293-e479-4d50-9b19-f7f354fe2f74",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
        },
        displayName: "Sarawut Thitinanthawornsuk",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "348510",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/348510",
    key: "DEM-248",
    fields: {
      summary: "New TMS Replacement",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ad5159293-e479-4d50-9b19-f7f354fe2f74",
        accountId: "712020:d5159293-e479-4d50-9b19-f7f354fe2f74",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/9045d406998ae85b9b85e1abc9ea78fd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FST-1.png",
        },
        displayName: "Sarawut Thitinanthawornsuk",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "344808",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/344808",
    key: "DEM-247",
    fields: {
      summary: "SRD Enhancement for backlog problems",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "337282",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/337282",
    key: "DEM-245",
    fields: {
      summary: "DC New Company",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6216ec2ac345490071980177",
        accountId: "6216ec2ac345490071980177",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
        },
        displayName: "Malai Boonyodom",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "337078",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/337078",
    key: "DEM-243",
    fields: {
      summary: "RMS Enhancement for backlog problems and requests 2024",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6195ab41d2e64c0071ad8af8",
        accountId: "6195ab41d2e64c0071ad8af8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
        },
        displayName: "sunisa",
        active: true,
        timeZone: "Asia/Vientiane",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "336713",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/336713",
    key: "DEM-241",
    fields: {
      summary: "Self Check Out.",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63f2d8260154af3bb2ae3739",
        accountId: "63f2d8260154af3bb2ae3739",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/21400307da6d386f03cc5b443b1c5b12?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-5.png",
          "24x24":
            "https://secure.gravatar.com/avatar/21400307da6d386f03cc5b443b1c5b12?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-5.png",
          "16x16":
            "https://secure.gravatar.com/avatar/21400307da6d386f03cc5b443b1c5b12?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-5.png",
          "32x32":
            "https://secure.gravatar.com/avatar/21400307da6d386f03cc5b443b1c5b12?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-5.png",
        },
        displayName: "Pannee Sukim",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "333032",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/333032",
    key: "DEM-239",
    fields: {
      summary: "Web Picking enhancement",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "331480",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/331480",
    key: "DEM-238",
    fields: {
      summary: "Smartsoft Automation weekly/month-end process",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "331103",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/331103",
    key: "DEM-237",
    fields: {
      summary: "CMA Hardware Replacement of Multichannel project",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "330267",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/330267",
    key: "DEM-236",
    fields: {
      summary:
        "[O2O][SOA][RMS] Apply auto sync pick zone data and new pick zone criteria to Highjump",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "330228",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/330228",
    key: "DEM-235",
    fields: {
      summary: "MY Infobip to Firemobile",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "330050",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/330050",
    key: "DEM-234",
    fields: {
      summary: "Improve Primary Booking System",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=60a5fa6a99b21f007010815d",
        accountId: "60a5fa6a99b21f007010815d",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
        },
        displayName: "Suprinya Jantawong",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "330049",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/330049",
    key: "DEM-233",
    fields: {
      summary: "TCS 2.0",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=60a5fa6a99b21f007010815d",
        accountId: "60a5fa6a99b21f007010815d",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
        },
        displayName: "Suprinya Jantawong",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "329769",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/329769",
    key: "DEM-232",
    fields: {
      summary: "MY Gov App Integration Project",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "329391",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/329391",
    key: "DEM-231",
    fields: {
      summary: "SRD Enhancement || Web Publisher​",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=622999df932f0f00716b1c2e",
        accountId: "622999df932f0f00716b1c2e",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
        },
        displayName: "Piengtawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "329286",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/329286",
    key: "DEM-230",
    fields: {
      summary: "Alert item stock for KL Reduction",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "328694",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/328694",
    key: "DEM-229",
    fields: {
      summary:
        "[O2O][Phoenix] Feed item, price, product catalog, product category and promotion from LDD to Phoenix",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "328247",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/328247",
    key: "DEM-228",
    fields: {
      summary: " Stock transfer between Lotus and Makro",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "327923",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/327923",
    key: "DEM-227",
    fields: {
      summary: "Project B2B Gas-Station BCP",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "327728",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/327728",
    key: "DEM-225",
    fields: {
      summary:
        "[O2O][Multichannel] Request RPM & Oracle SIM team to support for Multichannel system improvement project",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "326833",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/326833",
    key: "DEM-224",
    fields: {
      summary: "Trukr 's new API  (supporting BIC data)",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "326465",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/326465",
    key: "DEM-222",
    fields: {
      summary: "[TH] B2B In-Store CV Creation by interface with MyLotus",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "325891",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/325891",
    key: "DEM-221",
    fields: {
      summary: "Change Company Name: LPF to REIT",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=60deffca22c0920071caf493",
        accountId: "60deffca22c0920071caf493",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/cabbf609f6a6a19f95b9b30b5814558f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/cabbf609f6a6a19f95b9b30b5814558f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/cabbf609f6a6a19f95b9b30b5814558f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/cabbf609f6a6a19f95b9b30b5814558f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
        },
        displayName: "Thapat.soonthornnoppakun",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "315496",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/315496",
    key: "DEM-220",
    fields: {
      summary: "New HLE hub at Tha Yang",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A64605208-0992-4bcb-a5c0-6cfe2371f7cf",
        accountId: "712020:64605208-0992-4bcb-a5c0-6cfe2371f7cf",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/cf1ebe77b7f2368367044a694bdcf6aa?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTW-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/cf1ebe77b7f2368367044a694bdcf6aa?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTW-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/cf1ebe77b7f2368367044a694bdcf6aa?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTW-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/cf1ebe77b7f2368367044a694bdcf6aa?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FTW-1.png",
        },
        displayName: "Thanyanut Wuttithonvitit",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "312911",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/312911",
    key: "DEM-218",
    fields: {
      summary: "Harmony Min Change - Domain Data",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        accountId: "712020:515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
        },
        displayName: "Jeranan Kaewdee",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "312910",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/312910",
    key: "DEM-217",
    fields: {
      summary: "Harmony Min Change - Domain Product",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        accountId: "712020:515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
        },
        displayName: "Jeranan Kaewdee",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "312468",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/312468",
    key: "DEM-216",
    fields: {
      summary: "ORWMS Paperless ",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "310671",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/310671",
    key: "DEM-213",
    fields: {
      summary: "SFTP promotion file for Amaze project",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "307937",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/307937",
    key: "DEM-208",
    fields: {
      summary: "Couchbase On-Prem Upgrade Version 7.2",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=642262a06b29c052ab2ee412",
        accountId: "642262a06b29c052ab2ee412",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/da61cab687e10bd20b344b68d43cbfeb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FGA-4.png",
        },
        displayName: "Gavin Amsap",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "306843",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/306843",
    key: "DEM-205",
    fields: {
      summary: "BIC Enhancement",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "306842",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/306842",
    key: "DEM-204",
    fields: {
      summary: "Finance Data Warehouse Enhancement",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "306841",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/306841",
    key: "DEM-203",
    fields: {
      summary: "Analytics Cloud Optimization",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "306589",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/306589",
    key: "DEM-202",
    fields: {
      summary: "Cloud Migration",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "306477",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/306477",
    key: "DEM-201",
    fields: {
      summary: "EOL/EOS Store Infrastructure Network",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62e0d5ad3aaeedcae7560dfc",
        accountId: "62e0d5ad3aaeedcae7560dfc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "Teerawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "306476",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/306476",
    key: "DEM-200",
    fields: {
      summary: "EOL/EOS DC Infrastructure Network",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62e0d5ad3aaeedcae7560dfc",
        accountId: "62e0d5ad3aaeedcae7560dfc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "Teerawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "306475",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/306475",
    key: "DEM-199",
    fields: {
      summary: "EOL/EOS HO Infrastructure Network",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62e0d5ad3aaeedcae7560dfc",
        accountId: "62e0d5ad3aaeedcae7560dfc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/aeb089a12be480922ff41e7cbbae7015?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "Teerawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "306401",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/306401",
    key: "DEM-198",
    fields: {
      summary: "[Marketplace] : Request Thermal printer for AWB ",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10326",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Resubmit G1.5 -",
        id: "10326",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "306394",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/306394",
    key: "DEM-197",
    fields: {
      summary: "Customer and Loyalty Enhancement Y2024+",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61a85cbbb0b630006a30acbc",
        accountId: "61a85cbbb0b630006a30acbc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
        },
        displayName: "Srisuk Rujisaritwong",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "303541",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/303541",
    key: "DEM-195",
    fields: {
      summary: "Supply Chain Stabilization ",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6191e3eb6d002b006bf527f4",
        accountId: "6191e3eb6d002b006bf527f4",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/c6993ce1909d2bdde6df398b129f91ea?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/c6993ce1909d2bdde6df398b129f91ea?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/c6993ce1909d2bdde6df398b129f91ea?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/c6993ce1909d2bdde6df398b129f91ea?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-0.png",
        },
        displayName: "saichol",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "300108",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/300108",
    key: "DEM-194",
    fields: {
      summary: "Procurement Portal",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=627c7256694943006f357576",
        accountId: "627c7256694943006f357576",
        emailAddress: "vanasnan.wongwian@lotuss.com",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/de060d9e036d8f881fe097e4b0483bcc?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FV-1.png",
        },
        displayName: "vanasnanw",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "300107",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/300107",
    key: "DEM-193",
    fields: {
      summary: "5 Prasan Report",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62f36357da8620d53394e30b",
        accountId: "62f36357da8620d53394e30b",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
        },
        displayName: "Atcharapan Thongphet",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "297459",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/297459",
    key: "DEM-190",
    fields: {
      summary: "TH - Price Stabilization 2024 ",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6195ab41d2e64c0071ad8af8",
        accountId: "6195ab41d2e64c0071ad8af8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
        },
        displayName: "sunisa",
        active: true,
        timeZone: "Asia/Vientiane",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "297431",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/297431",
    key: "DEM-189",
    fields: {
      summary: "TH - RMS Stabilization 2024",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6195ab41d2e64c0071ad8af8",
        accountId: "6195ab41d2e64c0071ad8af8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/b4092f7fe69fa12a4996b6e384141378?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-3.png",
        },
        displayName: "sunisa",
        active: true,
        timeZone: "Asia/Vientiane",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "296787",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/296787",
    key: "DEM-188",
    fields: {
      summary: "HO Laptop Replacement",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        accountId: "712020:70e89ace-5f6a-47a3-8d42-1c9a849a5ae8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/84a7b80496e73ba109be598f21a47096?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWC-3.png",
        },
        displayName: "Worawut Charoenchai",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "294878",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/294878",
    key: "DEM-187",
    fields: {
      summary: "New Backhual system ",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=60a5fa6a99b21f007010815d",
        accountId: "60a5fa6a99b21f007010815d",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/5431a66a92ee5c35a9e8a1057410a003?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-6.png",
        },
        displayName: "Suprinya Jantawong",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "293085",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/293085",
    key: "DEM-185",
    fields: {
      summary: "Finance Enhancement",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62f36357da8620d53394e30b",
        accountId: "62f36357da8620d53394e30b",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
        },
        displayName: "Atcharapan Thongphet",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "293083",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/293083",
    key: "DEM-184",
    fields: {
      summary: "Unity Project: Change Company Info from Lotus's to be CPAxtra",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=60deffca22c0920071caf493",
        accountId: "60deffca22c0920071caf493",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/cabbf609f6a6a19f95b9b30b5814558f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/cabbf609f6a6a19f95b9b30b5814558f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/cabbf609f6a6a19f95b9b30b5814558f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/cabbf609f6a6a19f95b9b30b5814558f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
        },
        displayName: "Thapat.soonthornnoppakun",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10326",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Resubmit G1.5 -",
        id: "10326",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "290151",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/290151",
    key: "DEM-183",
    fields: {
      summary: "MY Customer Registration Flow Changes",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "288536",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/288536",
    key: "DEM-181",
    fields: {
      summary: "MY Azure 4Categories to 7Categories",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63328fcc07a27ebeff161308",
        accountId: "63328fcc07a27ebeff161308",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/6589c6cf06c6fcf2642b577655d23bb0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FA-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/6589c6cf06c6fcf2642b577655d23bb0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FA-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/6589c6cf06c6fcf2642b577655d23bb0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FA-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/6589c6cf06c6fcf2642b577655d23bb0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FA-6.png",
        },
        displayName: "amyy",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "288008",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/288008",
    key: "DEM-180",
    fields: {
      summary: "CMA Ordering",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "286788",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/286788",
    key: "DEM-177",
    fields: {
      summary: "System Monitoring",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A95b6ac6d-c1b6-4a4e-a2d9-959de3ea0310",
        accountId: "712020:95b6ac6d-c1b6-4a4e-a2d9-959de3ea0310",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/310864e1ece9955878945161a60ac0c2?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FA-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/310864e1ece9955878945161a60ac0c2?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FA-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/310864e1ece9955878945161a60ac0c2?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FA-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/310864e1ece9955878945161a60ac0c2?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FA-0.png",
        },
        displayName: "Arwut",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "286702",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/286702",
    key: "DEM-176",
    fields: {
      summary: "B2B SalesForce",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A8116cc43-6dbf-4e98-b97e-2ce655df403d",
        accountId: "712020:8116cc43-6dbf-4e98-b97e-2ce655df403d",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/3750ab8b510ecb793d661de881bb45e9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FE-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/3750ab8b510ecb793d661de881bb45e9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FE-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/3750ab8b510ecb793d661de881bb45e9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FE-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/3750ab8b510ecb793d661de881bb45e9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FE-0.png",
        },
        displayName: "EricWong",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "275869",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/275869",
    key: "DEM-173",
    fields: {
      summary: "Food Chain (Arabitia) - Stock Improvement",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "275860",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/275860",
    key: "DEM-171",
    fields: {
      summary: "Replacement UPS Battery and Scanner for Stores",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6414052b7222b08f3e717328",
        accountId: "6414052b7222b08f3e717328",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/58950264c03db9559c20ce4ed5e6e5be?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/58950264c03db9559c20ce4ed5e6e5be?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/58950264c03db9559c20ce4ed5e6e5be?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/58950264c03db9559c20ce4ed5e6e5be?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-0.png",
        },
        displayName: "Prasan.Jankarakul",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "273481",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/273481",
    key: "DEM-170",
    fields: {
      summary: "Acquisition Plan : BonusLink X Lotus’s MY",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "273480",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/273480",
    key: "DEM-169",
    fields: {
      summary: "Data Privacy - Retention Policy (Customer Data)",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "273335",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/273335",
    key: "DEM-168",
    fields: {
      summary: "Trade Promotion Tracking",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6180c6ad3e3753006f6f655a",
        accountId: "6180c6ad3e3753006f6f655a",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/eff2f8bdd10419cd99cdf16f5feba6d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPW-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/eff2f8bdd10419cd99cdf16f5feba6d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPW-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/eff2f8bdd10419cd99cdf16f5feba6d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPW-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/eff2f8bdd10419cd99cdf16f5feba6d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPW-1.png",
        },
        displayName: "PanaEk Warawit",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "272169",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/272169",
    key: "DEM-166",
    fields: {
      summary: "E-invoice Malaysia",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=634510ba48be855a65ec589d",
        accountId: "634510ba48be855a65ec589d",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/43648563598c525540625eca860dd288?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FEO-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/43648563598c525540625eca860dd288?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FEO-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/43648563598c525540625eca860dd288?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FEO-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/43648563598c525540625eca860dd288?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FEO-6.png",
        },
        displayName: "Eugene Oon",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "272167",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/272167",
    key: "DEM-165",
    fields: {
      summary: "GA Implementation",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Afe90e570-6f0d-49f1-ac3d-af867af03e97",
        accountId: "712020:fe90e570-6f0d-49f1-ac3d-af867af03e97",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/bfb61cedc96bfb01c2f55156b3f1c26f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPT-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/bfb61cedc96bfb01c2f55156b3f1c26f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPT-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/bfb61cedc96bfb01c2f55156b3f1c26f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPT-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/bfb61cedc96bfb01c2f55156b3f1c26f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPT-2.png",
        },
        displayName: "Pei Lin Tan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "270499",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/270499",
    key: "DEM-162",
    fields: {
      summary: "Problem Tracking all function (IT DC FM QA) for Small format",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Aa23f585b-929c-4666-b24e-1a8d00111bef",
        accountId: "712020:a23f585b-929c-4666-b24e-1a8d00111bef",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/697becfd2011283c733833d42198c038?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/697becfd2011283c733833d42198c038?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/697becfd2011283c733833d42198c038?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/697becfd2011283c733833d42198c038?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
        },
        displayName: "Thames",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "270496",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/270496",
    key: "DEM-161",
    fields: {
      summary: "CR - Service Desk (Outsystem)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63c12913c52a63dcda838ebe",
        accountId: "63c12913c52a63dcda838ebe",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/9f2cfe79b9beb79710e74e1b4db0d91b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/9f2cfe79b9beb79710e74e1b4db0d91b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/9f2cfe79b9beb79710e74e1b4db0d91b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/9f2cfe79b9beb79710e74e1b4db0d91b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-1.png",
        },
        displayName: "Pattanun.Sittikankaew",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "265166",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/265166",
    key: "DEM-160",
    fields: {
      summary: "B2B Collaboration (CPAxtra & Lotus's)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        accountId: "712020:0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
        },
        displayName: "anoot charoenkiattikul",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "265165",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/265165",
    key: "DEM-159",
    fields: {
      summary: "VM Ware Revolution",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6414052e9d2bc6c90a8a2acc",
        accountId: "6414052e9d2bc6c90a8a2acc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/a46b15a93bf33116bdddc94803c6d500?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FW-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/a46b15a93bf33116bdddc94803c6d500?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FW-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/a46b15a93bf33116bdddc94803c6d500?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FW-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/a46b15a93bf33116bdddc94803c6d500?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FW-2.png",
        },
        displayName: "wattayutt",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "265164",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/265164",
    key: "DEM-158",
    fields: {
      summary: "MILO (Server Migration)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6389ab775fc160544e1ca916",
        accountId: "6389ab775fc160544e1ca916",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ffbae329bc6b2ceb142109b916b595e8?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ffbae329bc6b2ceb142109b916b595e8?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ffbae329bc6b2ceb142109b916b595e8?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ffbae329bc6b2ceb142109b916b595e8?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
        },
        displayName: "Theerawat.Dhevanrattanakomol",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "265163",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/265163",
    key: "DEM-157",
    fields: {
      summary: "IT Asset Management (TAR)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A41c2ea3d-d2c9-4939-a1e1-89287c293533",
        accountId: "712020:41c2ea3d-d2c9-4939-a1e1-89287c293533",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/72a9baf5e8143aa250a5385879e6789e?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FR-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/72a9baf5e8143aa250a5385879e6789e?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FR-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/72a9baf5e8143aa250a5385879e6789e?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FR-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/72a9baf5e8143aa250a5385879e6789e?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FR-0.png",
        },
        displayName: "RattanGo",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "258634",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/258634",
    key: "DEM-100",
    fields: {
      summary: "DM-155 - Digitalized Talent Acquisition phase2",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61f1fdbde218f0006af7eef9",
        accountId: "61f1fdbde218f0006af7eef9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "tavikarn.paisoonsin",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "258633",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/258633",
    key: "DEM-99",
    fields: {
      summary: "DM-154 - HR Database – Insight Phase2",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61f1fdbde218f0006af7eef9",
        accountId: "61f1fdbde218f0006af7eef9",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/53e3d6b9d46847d4e7f1aca2ac0d8930?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-4.png",
        },
        displayName: "tavikarn.paisoonsin",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "258631",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/258631",
    key: "DEM-97",
    fields: {
      summary: "DM-152 - WFM Rollout",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        accountId: "712020:515a7dbd-c4c0-4a98-ac22-5cf8eec2ebf6",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/e7f0bd868e6acfefd3455c9a46ffdb4b?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FJK-0.png",
        },
        displayName: "Jeranan Kaewdee",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "258630",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/258630",
    key: "DEM-96",
    fields: {
      summary: "DM-151 - Finance & Procurement Report Improvement",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62f36357da8620d53394e30b",
        accountId: "62f36357da8620d53394e30b",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/54ff70541d36b868a084bf5148043e1c?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAT-4.png",
        },
        displayName: "Atcharapan Thongphet",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "253335",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/253335",
    key: "DEM-95",
    fields: {
      summary: "DM-150 - Automate Warehouse (Patthanakarn)​",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        accountId: "712020:0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
        },
        displayName: "anoot charoenkiattikul",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "248538",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/248538",
    key: "DEM-88",
    fields: {
      summary: "DM-143 Customer Engagement via WA​",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "248536",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/248536",
    key: "DEM-86",
    fields: {
      summary: "DM-141 WH Tax agent​",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ae05d601e-0c9a-4861-8334-56d424153b19",
        accountId: "712020:e05d601e-0c9a-4861-8334-56d424153b19",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
        },
        displayName: "Natchinda Rungjaroenporn",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "247944",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/247944",
    key: "DEM-85",
    fields: {
      summary: "DM140 - Daily Production Planning​",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6224ea33a124500068895011",
        accountId: "6224ea33a124500068895011",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/f966f0df6d20436a98bcb3a2420f3576?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/f966f0df6d20436a98bcb3a2420f3576?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/f966f0df6d20436a98bcb3a2420f3576?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/f966f0df6d20436a98bcb3a2420f3576?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FC-3.png",
        },
        displayName: "chatchaya",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "244601",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/244601",
    key: "DEM-81",
    fields: {
      summary: "DM-136 Referral Code",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=638ecef99e48f2b9a6164fe7",
        accountId: "638ecef99e48f2b9a6164fe7",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/0df1790d2f1bf3c797bd4b2fd41587f6?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FK-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/0df1790d2f1bf3c797bd4b2fd41587f6?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FK-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/0df1790d2f1bf3c797bd4b2fd41587f6?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FK-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/0df1790d2f1bf3c797bd4b2fd41587f6?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FK-0.png",
        },
        displayName: "Kanokrada",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "244592",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/244592",
    key: "DEM-80",
    fields: {
      summary: "DM-135 New North DC",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6224ea65932f0f007169024a",
        accountId: "6224ea65932f0f007169024a",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/795ad55db472e660458a9dcc8fac8b09?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FWS-2.png",
        },
        displayName: "wasuthep sarawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "239102",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/239102",
    key: "DEM-77",
    fields: {
      summary: "DM-132 Lotus's Mall Booking​",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63c129110913ada386ab422d",
        accountId: "63c129110913ada386ab422d",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
        },
        displayName: "Nattanan.Rukchang",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "239090",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/239090",
    key: "DEM-72",
    fields: {
      summary:
        "DM-127 Improve Stock Sync between Catalog service and stock API​",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238986",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238986",
    key: "DEM-70",
    fields: {
      summary: "DM-071 Replacement BY TMS",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=60483482bfef95006bc8e8c2",
        accountId: "60483482bfef95006bc8e8c2",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
        },
        displayName: "Songkiat.Sanchai",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10326",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Resubmit G1.5 -",
        id: "10326",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238696",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238696",
    key: "DEM-62",
    fields: {
      summary: "DM-118 Change PBL to PBS at DC 914",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62abe957f8bd790069d9fa17",
        accountId: "62abe957f8bd790069d9fa17",
        avatarUrls: {
          "48x48":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/62abe957f8bd790069d9fa17/d2fb273e-c3d3-4290-b2da-5e0476ff5b19/48",
          "24x24":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/62abe957f8bd790069d9fa17/d2fb273e-c3d3-4290-b2da-5e0476ff5b19/24",
          "16x16":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/62abe957f8bd790069d9fa17/d2fb273e-c3d3-4290-b2da-5e0476ff5b19/16",
          "32x32":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/62abe957f8bd790069d9fa17/d2fb273e-c3d3-4290-b2da-5e0476ff5b19/32",
        },
        displayName: "Phatthara Pisootrapee",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10320",
        description:
          "The issue is open and ready for the assignee to start work on it.",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Open",
        id: "10320",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238695",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238695",
    key: "DEM-61",
    fields: {
      summary: "DM-093 E-Tax invoice",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=634cdd061db4d2ebcf60c927",
        accountId: "634cdd061db4d2ebcf60c927",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/cc0fcf0e85a2f1e7f16eb01af37aebb1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/cc0fcf0e85a2f1e7f16eb01af37aebb1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/cc0fcf0e85a2f1e7f16eb01af37aebb1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/cc0fcf0e85a2f1e7f16eb01af37aebb1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
        },
        displayName: "Phagasinee.Boottho",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238511",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238511",
    key: "DEM-60",
    fields: {
      summary: "DM-056 [Report] DI report BOL/IBT (SmartSoft - RMS19)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6216ec2ac345490071980177",
        accountId: "6216ec2ac345490071980177",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
        },
        displayName: "Malai Boonyodom",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238510",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238510",
    key: "DEM-59",
    fields: {
      summary: "DM-057 Commercial Income allocation by item phase 1 (CN)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61922b24b0b630006a3e78b8",
        accountId: "61922b24b0b630006a3e78b8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
        },
        displayName: "Yaowaluck Peyaprapapun",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10325",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Gate 2 -",
        id: "10325",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238508",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238508",
    key: "DEM-57",
    fields: {
      summary: "DM-059 Bulk Approval / Rejection",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61922b24b0b630006a3e78b8",
        accountId: "61922b24b0b630006a3e78b8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
        },
        displayName: "Yaowaluck Peyaprapapun",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238507",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238507",
    key: "DEM-56",
    fields: {
      summary: "DM-061 MSP (Makro Supplier Portal) - Item Maintenance Module",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61922b24b0b630006a3e78b8",
        accountId: "61922b24b0b630006a3e78b8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
        },
        displayName: "Yaowaluck Peyaprapapun",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238506",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238506",
    key: "DEM-55",
    fields: {
      summary: "DM-063 Store Innovation",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A2ac87363-445a-4142-a611-6b5b69e04006",
        accountId: "712020:2ac87363-445a-4142-a611-6b5b69e04006",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/8bcff43bde7dda4c9cadbb4dea70c414?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FCP-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/8bcff43bde7dda4c9cadbb4dea70c414?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FCP-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/8bcff43bde7dda4c9cadbb4dea70c414?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FCP-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/8bcff43bde7dda4c9cadbb4dea70c414?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FCP-2.png",
        },
        displayName: "Chartchai phongpaew",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238504",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238504",
    key: "DEM-53",
    fields: {
      summary: "DM-069 Replacement BY FnR",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=60483482bfef95006bc8e8c2",
        accountId: "60483482bfef95006bc8e8c2",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
        },
        displayName: "Songkiat.Sanchai",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238503",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238503",
    key: "DEM-52",
    fields: {
      summary: "DM-070 Replacement BY SRD",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=60483482bfef95006bc8e8c2",
        accountId: "60483482bfef95006bc8e8c2",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/eb29ad6e4231835cbb606ca222aa2c90?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-2.png",
        },
        displayName: "Songkiat.Sanchai",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238502",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238502",
    key: "DEM-51",
    fields: {
      summary: "DM-073 Coupon type Non loyalty Issue",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61a85cbbb0b630006a30acbc",
        accountId: "61a85cbbb0b630006a30acbc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
        },
        displayName: "Srisuk Rujisaritwong",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238498",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238498",
    key: "DEM-47",
    fields: {
      summary: "DM-077 Shopping list rollout",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63c129110913ada386ab422d",
        accountId: "63c129110913ada386ab422d",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/7492e6b0766f4871e550879e4ad20ff0?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FN-4.png",
        },
        displayName: "Nattanan.Rukchang",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238497",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238497",
    key: "DEM-46",
    fields: {
      summary: "DM-078 CP Axtra Project",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6216ec2ac345490071980177",
        accountId: "6216ec2ac345490071980177",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
        },
        displayName: "Malai Boonyodom",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238496",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238496",
    key: "DEM-45",
    fields: {
      summary: "DM-079 CPFM Transition System to SIM & R10",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6216ec2ac345490071980177",
        accountId: "6216ec2ac345490071980177",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
        },
        displayName: "Malai Boonyodom",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238493",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238493",
    key: "DEM-42",
    fields: {
      summary: "DM-084 Cost saving reduce POG license",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=622999df932f0f00716b1c2e",
        accountId: "622999df932f0f00716b1c2e",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "24x24":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "16x16":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
          "32x32":
            "https://secure.gravatar.com/avatar/290cdbb73a8906ba56b981c1d0b9d9d1?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-6.png",
        },
        displayName: "Piengtawan",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238487",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238487",
    key: "DEM-36",
    fields: {
      summary: "DM-090 HLE Phase 2",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61a85cbbb0b630006a30acbc",
        accountId: "61a85cbbb0b630006a30acbc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
        },
        displayName: "Srisuk Rujisaritwong",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238485",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238485",
    key: "DEM-34",
    fields: {
      summary: "DM-092 Combine BN HLE Hub into 7889?",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238483",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238483",
    key: "DEM-32",
    fields: {
      summary: "DM-096 DI report Project B2C VM",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6216ec2ac345490071980177",
        accountId: "6216ec2ac345490071980177",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
        },
        displayName: "Malai Boonyodom",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238481",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238481",
    key: "DEM-30",
    fields: {
      summary: "DM-100 Lotus's x LMP New Loyalty Fully Integration",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61715afa26a54000713fe7ce",
        accountId: "61715afa26a54000713fe7ce",
        avatarUrls: {
          "48x48":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/61715afa26a54000713fe7ce/1624e62d-a318-4391-afe7-c1ce68bd9d21/48",
          "24x24":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/61715afa26a54000713fe7ce/1624e62d-a318-4391-afe7-c1ce68bd9d21/24",
          "16x16":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/61715afa26a54000713fe7ce/1624e62d-a318-4391-afe7-c1ce68bd9d21/16",
          "32x32":
            "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/61715afa26a54000713fe7ce/1624e62d-a318-4391-afe7-c1ce68bd9d21/32",
        },
        displayName: "Wanwisa.Sukudom",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238479",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238479",
    key: "DEM-28",
    fields: {
      summary: "DM-104 SMO 1Oper many WH",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6216ec2ac345490071980177",
        accountId: "6216ec2ac345490071980177",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/c634c283f4e1501d5404c260ac99465f?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FMB-2.png",
        },
        displayName: "Malai Boonyodom",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238478",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238478",
    key: "DEM-27",
    fields: {
      summary: "DM-105 Finance Data Warehouse",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=63e5b999491b20ef64bdc3d3",
        accountId: "63e5b999491b20ef64bdc3d3",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/32fd5fffd326b33abbdace0a7a5b5ceb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/32fd5fffd326b33abbdace0a7a5b5ceb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/32fd5fffd326b33abbdace0a7a5b5ceb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/32fd5fffd326b33abbdace0a7a5b5ceb?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSJ-0.png",
        },
        displayName: "Sorasak Jackaranukul",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238476",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238476",
    key: "DEM-25",
    fields: {
      summary: "DM-108 MFC at Patanakarn Store",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3A0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        accountId: "712020:0d61fe38-4e7b-4273-9dfb-546f5be2b0b5",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "24x24":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "16x16":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
          "32x32":
            "https://secure.gravatar.com/avatar/dbf5448e8ad7d15a2044ad6a018bf6cd?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FAC-3.png",
        },
        displayName: "anoot charoenkiattikul",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238474",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238474",
    key: "DEM-23",
    fields: {
      summary: "DM-110 Amaze ID Integration",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61a85cbbb0b630006a30acbc",
        accountId: "61a85cbbb0b630006a30acbc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "24x24":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "16x16":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
          "32x32":
            "https://secure.gravatar.com/avatar/8bafd3fd5f5a6aaea52a8e3d69b273f7?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FSR-1.png",
        },
        displayName: "Srisuk Rujisaritwong",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238470",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238470",
    key: "DEM-19",
    fields: {
      summary: "DM-121 Store and DC Hardware replacement",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6414052b7222b08f3e717328",
        accountId: "6414052b7222b08f3e717328",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/58950264c03db9559c20ce4ed5e6e5be?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/58950264c03db9559c20ce4ed5e6e5be?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/58950264c03db9559c20ce4ed5e6e5be?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/58950264c03db9559c20ce4ed5e6e5be?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FP-0.png",
        },
        displayName: "Prasan.Jankarakul",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238469",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238469",
    key: "DEM-18",
    fields: {
      summary: "DM-122 FM Maintenance (TH, MY) (IT Cost Saving)",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Ae05d601e-0c9a-4861-8334-56d424153b19",
        accountId: "712020:e05d601e-0c9a-4861-8334-56d424153b19",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/ae7ca7cb88d1106be871023b6df2b6ef?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNR-0.png",
        },
        displayName: "Natchinda Rungjaroenporn",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238468",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238468",
    key: "DEM-17",
    fields: {
      summary: "DM-123 Electronic Expo 2 (HLE) 2",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238466",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238466",
    key: "DEM-15",
    fields: {
      summary: "DM-125 Store Database Cost Optimize",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=62c7d7eba152cf973645aa14",
        accountId: "62c7d7eba152cf973645aa14",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/0748cd5ca47283acd4b3c93610468ee9?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FNL-4.png",
        },
        displayName: "Nunnapat L.",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238465",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238465",
    key: "DEM-14",
    fields: {
      summary: "DM-126 PCI DSS Audit FY. 2023",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=61922b24b0b630006a3e78b8",
        accountId: "61922b24b0b630006a3e78b8",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "24x24":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "16x16":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
          "32x32":
            "https://secure.gravatar.com/avatar/1f9329f18d4f154366ec6afa38b13889?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYP-4.png",
        },
        displayName: "Yaowaluck Peyaprapapun",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10324",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Waiting submit to Gate 2 -",
        id: "10324",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/2",
          id: 2,
          key: "new",
          colorName: "blue-gray",
          name: "To Do",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238175",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238175",
    key: "DEM-11",
    fields: {
      summary: "DM-062 ITSM Replacement",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=712020%3Aa23f585b-929c-4666-b24e-1a8d00111bef",
        accountId: "712020:a23f585b-929c-4666-b24e-1a8d00111bef",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/697becfd2011283c733833d42198c038?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/697becfd2011283c733833d42198c038?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/697becfd2011283c733833d42198c038?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/697becfd2011283c733833d42198c038?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FT-0.png",
        },
        displayName: "Thames",
        active: false,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238174",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238174",
    key: "DEM-10",
    fields: {
      summary: "DM-065 Demand Request_Fusionex Services wt Lotus's Malaysia",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238173",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238173",
    key: "DEM-9",
    fields: {
      summary: "DM-066 ExaCC Migrate to VM (Low cost)",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238172",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238172",
    key: "DEM-8",
    fields: {
      summary: "DM-067 Reduce Vmware Cost (Nutalix)",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238171",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238171",
    key: "DEM-7",
    fields: {
      summary: "DM-068 BMC ITOM Replacement",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238170",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238170",
    key: "DEM-6",
    fields: {
      summary: "DM-072 TIMS HW server and IBM-DB2 Standard",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6191e3eb6d002b006bf527f4",
        accountId: "6191e3eb6d002b006bf527f4",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/c6993ce1909d2bdde6df398b129f91ea?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-0.png",
          "24x24":
            "https://secure.gravatar.com/avatar/c6993ce1909d2bdde6df398b129f91ea?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-0.png",
          "16x16":
            "https://secure.gravatar.com/avatar/c6993ce1909d2bdde6df398b129f91ea?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-0.png",
          "32x32":
            "https://secure.gravatar.com/avatar/c6993ce1909d2bdde6df398b129f91ea?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FS-0.png",
        },
        displayName: "saichol",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238168",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238168",
    key: "DEM-4",
    fields: {
      summary:
        "DM-097 SmartSoft PO integration with RMS19 (B2B SIS, DTS) project",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=6219ce839cd4a70071e06fbc",
        accountId: "6219ce839cd4a70071e06fbc",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
          "24x24":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
          "16x16":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
          "32x32":
            "https://secure.gravatar.com/avatar/f3dfe0992332e98b6b35b5e9d95c3735?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPS-2.png",
        },
        displayName: "Patchanee Sutadarat",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238167",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238167",
    key: "DEM-3",
    fields: {
      summary: "DM-099 Integrate Planogram Product Ranges to Stock Service",
      assignee: {
        self: "https://lotusretails.atlassian.net/rest/api/3/user?accountId=629ecd1f932059006f8f4a1e",
        accountId: "629ecd1f932059006f8f4a1e",
        avatarUrls: {
          "48x48":
            "https://secure.gravatar.com/avatar/29def639b0b8e2742bc99060a9e05c5a?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPC-5.png",
          "24x24":
            "https://secure.gravatar.com/avatar/29def639b0b8e2742bc99060a9e05c5a?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPC-5.png",
          "16x16":
            "https://secure.gravatar.com/avatar/29def639b0b8e2742bc99060a9e05c5a?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPC-5.png",
          "32x32":
            "https://secure.gravatar.com/avatar/29def639b0b8e2742bc99060a9e05c5a?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FPC-5.png",
        },
        displayName: "Paveesuda Ch.",
        active: true,
        timeZone: "Asia/Bangkok",
        accountType: "atlassian",
      },
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
  {
    expand:
      "operations,versionedRepresentations,editmeta,changelog,renderedFields",
    id: "238165",
    self: "https://lotusretails.atlassian.net/rest/api/3/issue/238165",
    key: "DEM-1",
    fields: {
      summary: "DM-116 EOS/EOL (Network Equipment)",
      assignee: null,
      duedate: null,
      status: {
        self: "https://lotusretails.atlassian.net/rest/api/3/status/10328",
        description: "",
        iconUrl: "https://lotusretails.atlassian.net/",
        name: "Investment Committee",
        id: "10328",
        statusCategory: {
          self: "https://lotusretails.atlassian.net/rest/api/3/statuscategory/4",
          id: 4,
          key: "indeterminate",
          colorName: "yellow",
          name: "In Progress",
        },
      },
    },
  },
];

export default PMO_DATA;
